/* External dependencies */
import CognitoClient from '@mancho.devs/cognito';
import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
// import { ofType } from 'redux-observable';

/* Local dependencies */
import {
  signupSucceeded,
  signupFailed,
  MainModalActionTypes,
  SignUp,
  MainModalActions,
  signupConfirmCodeSucceeded,
  signupConfirmCodeFailed,
  ConfirmCode,
  SetSignInEmailPassword,
  signinSucceeded,
  signinFailed,
  ForgotPassword,
  forgotPasswordRequestSucceeded,
  forgotPasswordRequestFailed,
  SigninConfirmPassword,
  signinConfirmPasswordSucceeded,
  // signOutRequest,
  signOutRequestSucceeded,
  signinConfirmPasswordFailed,
} from './action';

const cognitoClient = new CognitoClient({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID,
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID,
});

export function signUpEpic(action$): Observable<MainModalActions> {
  return action$.pipe(
    filter((action: MainModalActions) => action.type === MainModalActionTypes.SIGNUP),
    switchMap(({ username, password }: SignUp) =>
      cognitoClient.signUp(username, password).then(signupSucceeded).catch(signupFailed),
    ),
  );
}
export function confirmCodeEpic(action$): Observable<MainModalActions> {
  return action$.pipe(
    filter((action: MainModalActions) => action.type === MainModalActionTypes.CONFIRM_CODE),
    switchMap(({ username, code }: ConfirmCode) =>
      cognitoClient.signUpConfirmCode(username, code).then(signupConfirmCodeSucceeded).catch(signupConfirmCodeFailed),
    ),
  );
}
export function signInEpic(action$): Observable<MainModalActions> {
  return action$.pipe(
    filter((action: MainModalActions) => action.type === MainModalActionTypes.SET_SIGNIN_EMAIL_PASSWORD),
    switchMap(({ username, password }: SetSignInEmailPassword) =>
      cognitoClient.signIn(username, password).then(signinSucceeded).catch(signinFailed),
    ),
  );
}
export function forgotPasswordEpic(action$): Observable<MainModalActions> {
  return action$.pipe(
    filter((action: MainModalActions) => action.type === MainModalActionTypes.FORGOT_PASSWORD),
    switchMap(({ username }: ForgotPassword) =>
      cognitoClient.forgotPassword(username).then(forgotPasswordRequestSucceeded).catch(forgotPasswordRequestFailed),
    ),
  );
}
export function signinConfirmPasswordEpic(action$): Observable<MainModalActions> {
  return action$.pipe(
    // ofType(MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD),
    filter((action: MainModalActions) => action.type === MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD),
    switchMap(({ username, code, password }: SigninConfirmPassword) =>
      cognitoClient
        .confirmPassword(username, code, password)
        .then(signinConfirmPasswordSucceeded)
        .catch(signinConfirmPasswordFailed),
    ),
  );
}
export function signOutEpic(action$): Observable<MainModalActions> {
  return action$.pipe(
    filter((action: MainModalActions) => action.type === MainModalActionTypes.SIGN_OUT_REQUEST),
    switchMap(() => cognitoClient.signOut().then(signOutRequestSucceeded)),
  );
}
