/* External dependencies */
import { applyMiddleware, combineReducers, createStore as createReduxStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { combineEpics, createEpicMiddleware } from 'redux-observable';

/* Local dependencies */
// import getDevices from '../components/devices/getDevices/redux/reducer';
// import { signinReducer } from '../components/access/redux/reducer';
import { mainModalReducer } from '../components/mainmodul/redux/reducer';
import {
  confirmCodeEpic,
  forgotPasswordEpic,
  signInEpic,
  signOutEpic,
  signUpEpic,
} from '../components/mainmodul/redux/epic';

const rootEpic = combineEpics(signUpEpic, confirmCodeEpic, signInEpic, forgotPasswordEpic, signOutEpic);

const rootReducer = combineReducers({
  // getDevices,
  // signinReducer,
  mainModalReducer,
});

let store;

export function createStore(): Store {
  const epicMiddleware = createEpicMiddleware();
  store = createReduxStore(rootReducer, composeWithDevTools(applyMiddleware(epicMiddleware)));

  epicMiddleware.run(rootEpic);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
