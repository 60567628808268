import { MainModalActionTypes, MainModalActions, StageSteps } from './action';
import update from 'immutability-helper';

export interface MainModalState {
  stage: StageSteps;
  username: string;
  emailError: string;
  passwordError: string;
  successEmail: boolean;
  isOpenModal: boolean;
  password: string;
  repeatPassword: string;
  repeatPasswordError: string;
  code: string;
  errorCode: string;
  loading: boolean;
  success: boolean;
  userEmail: string | null;
  email: string;
  signupSucceeded: boolean;
  signinSucceeded: boolean;
  signinPassword: string;
  signupPassword: string;
  signinConfirmPassword: string;
  succeeded: boolean;
  error?: Error;
}

export const initialState: MainModalState = {
  stage: StageSteps.INTRO,
  username: '',
  emailError: '',
  passwordError: '',
  successEmail: false,
  isOpenModal: false,
  password: '',
  repeatPassword: '',
  repeatPasswordError: '',
  code: '',
  errorCode: '',
  loading: false,
  success: false,
  userEmail: null,
  email: '',
  signupSucceeded: null,
  signinSucceeded: null,
  signinPassword: '',
  signinConfirmPassword: '',
  signupPassword: '',
  succeeded: false,
};

export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$#!%*?&]{8,}$/;

export const mainModalReducer = (state: MainModalState = initialState, action: MainModalActions): MainModalState => {
  switch (action.type) {
    case MainModalActionTypes.RESET_MAINMODUL_ERRORS:
      return update(state, {
        emailError: { $set: '' },
        passwordError: { $set: '' },
        repeatPasswordError: { $set: '' },
        $unset: ['error'],
      });
    case MainModalActionTypes.SIGN_OUT_REQUEST:
      return update(state, {
        succeeded: { $set: false },
      });
    case MainModalActionTypes.SIGN_OUT_REQUEST_SUCCEEDED:
      return {
        ...initialState,
        isOpenModal: true,
      };
    case MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD_FAILED:
      return update(state, {
        stage: { $set: StageSteps.SIGNIN_RECEIVE_CODE },
      });
    case MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD_SUCCEEDED:
      return update(state, {
        loading: { $set: true },
      });
    case MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD:
      return update(state, {
        username: { $set: action.username },
        code: { $set: action.code },
        // signinConfirmPassword: { $set: action.password },
        loading: { $set: true },
      });
    case MainModalActionTypes.FORGOT_PASSWORD_REQUEST_FAILED:
      return update(state, {
        stage: { $set: StageSteps.SIGNIN_NEW_PASSWORD },
      });
    case MainModalActionTypes.FORGOT_PASSWORD_REQUEST_SUCCEEDED:
      return update(state, {
        loading: { $set: true },
      });
    case MainModalActionTypes.FORGOT_PASSWORD:
      return update(state, {
        username: { $set: action.username },
      });
    case MainModalActionTypes.SIGNIN_FAILED:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });
    case MainModalActionTypes.SIGNIN_SUCCEEDED:
      return update(state, {
        stage: { $set: StageSteps.INTRO },
        isOpenModal: { $set: false },
        userEmail: { $set: state.username },
        loading: { $set: false },
        signinSucceeded: { $set: true },
      });
    case MainModalActionTypes.CONFIRM_CODE:
      const { code } = action;
      if (action.code) {
        return update(state, {
          $unset: ['errorCode'],
          loading: { $set: true },
        });
      } else {
        return state;
      }
    case MainModalActionTypes.SIGNUP_CONFIRM_CODE_SUCCEEDED:
      return update(state, {
        stage: { $set: StageSteps.INTRO },
        isOpenModal: { $set: false },
        signupSucceeded: { $set: true },
      });
    case MainModalActionTypes.SIGNUP:
      if (action.password) {
        return update(state, {
          username: { $set: action.username },
          signupPassword: { $set: action.password },
          loading: { $set: true },
        });
      }
      return state;
    case MainModalActionTypes.SIGNUP_FAILED:
      return update(state, {
        loading: { $set: false },
      });
    case MainModalActionTypes.SIGNUP_SUCCEEDED:
      return update(state, {
        stage: { $set: StageSteps.REGISTER_CODE },
        userEmail: { $set: state.username },
        loading: { $set: false },
      });
    case MainModalActionTypes.SET_SIGNIN_EMAIL_PASSWORD:
      if (action.username.match(emailRegex) && passwordRegex.test(action.password)) {
        return update(state, {
          username: { $set: action.username },
          password: { $set: action.password },
          loading: { $set: true },
        });
      } else {
        return update(state, {
          username: { $set: action.username },
          emailError: { $set: 'Invalid Email' },
          password: { $set: action.password },
          passwordError: { $set: 'Invalid password' },
          loading: { $set: true },
        });
      }
    case MainModalActionTypes.CHANGE_MODAL:
      return update(state, {
        isOpenModal: { $set: action.open },
      });
    case MainModalActionTypes.SET_STAGE:
      return {
        ...state,
        stage: action.payload,
      };
    case MainModalActionTypes.SET_REGISTER_EMAIL:
      if (action.username.match(emailRegex)) {
        return update(state, {
          username: { $set: action.username },
          emailError: { $set: '' },
        });
      } else {
        return update(state, {
          username: { $set: action.username },
          emailError: { $set: 'Invalid Email' },
        });
      }
    case MainModalActionTypes.SET_SIGNUP_CODE:
      return update(state, {
        code: { $set: action.code },
      });
    case MainModalActionTypes.SET_PASSWORD:
      if (action.password.match(passwordRegex)) {
        return update(state, {
          password: { $set: action.password },
          passwordError: { $set: '' },
        });
      } else {
        return update(state, {
          password: { $set: action.password },
          passwordError: { $set: 'Invalid password' },
        });
      }
    case MainModalActionTypes.SET_SECOND_PASSWORD:
      // console.log(action.password, '!!!!!!!!');
      if (action.password === state.password) {
        return update(state, {
          repeatPassword: { $set: action.password },
          repeatPasswordError: { $set: '' },
        });
      } else {
        return update(state, {
          repeatPassword: { $set: action.password },
          repeatPasswordError: { $set: 'Passwords should match' },
        });
      }
    default:
      return state;
  }
};
