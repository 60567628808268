export enum StageSteps {
  INTRO = 'INTRO',
  REGISTER_EMAIL = 'REGISTER_EMAIL',
  EMAIL_VALID = 'EMAIL_VALID',
  REGISTER_PASSWORD = 'REGISTER_PASSWORD',
  REGISTER_CODE = 'REGISTER_CODE',
  REGISTER_VERIFY = 'REGISTER_VERIFY',
  SIGNIN_LOGIN = 'SIGNIN_LOGIN',
  SIGNIN_PASSWORD_RECOVERY = 'SIGNIN_PASSWORD_RECOVERY',
  SIGNIN_RECEIVE_CODE = 'SIGNIN_RECEIVE_CODE',
  SIGNIN_NEW_PASSWORD = 'SIGNIN_NEW_PASSWORD',
}

export enum MainModalActionTypes {
  SET_STAGE = 'SET_STAGE',
  SET_REGISTER_EMAIL = 'SET_REGISTER_EMAIL',
  CHANGE_MODAL = 'CHANGE_MODAL',
  SET_PASSWORD = 'SET_PASSWORD',
  SET_SECOND_PASSWORD = 'SET_SECOND_PASSWORD',
  SET_SIGNUP_CODE = 'SET_SIGNUP_CODE',
  CONFIRM_PASSWORD = 'CONFIRM_PASSWORD',
  CONFIRM_CODE = 'CONFIRM_CODE',
  SET_SIGNIN_EMAIL_PASSWORD = 'SET_SIGNIN_EMAIL_PASSWORD',
  SIGNUP_SUCCEEDED = 'SIGNUP_SUCCEEDED',
  SIGNUP_FAILED = 'SIGNUP_FAILED',
  SIGNIN_SUCCEEDED = 'SIGNIN_SUCCEEDED',
  SIGNIN_FAILED = 'SIGNIN_FAILED',
  SIGNUP = 'SIGNUP',
  SIGNUP_CONFIRM_CODE_SUCCEEDED = 'SIGNUP_CONFIRM_CODE_SUCCEEDED',
  SIGNUP_CONFIRM_CODE_FAILED = 'SIGNUP_CONFIRM_CODE_FAILED',
  FORGOT_PASSWORD = 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_REQUEST_SUCCEEDED = 'FORGOT_PASSWORD_REQUEST_SUCCEEDED',
  FORGOT_PASSWORD_REQUEST_FAILED = 'FORGOT_PASSWORD_REQUEST_FAILED',
  SIGNIN_CONFIRM_PASSWORD = 'SIGNIN_CONFIRM_PASSWORD',
  SIGNIN_CONFIRM_PASSWORD_SUCCEEDED = 'SIGNIN_CONFIRM_PASSWORD_SUCCEEDED',
  SIGNIN_CONFIRM_PASSWORD_FAILED = 'SIGNIN_CONFIRM_PASSWORD_FAILED',
  SIGN_OUT_REQUEST = 'SIGN_OUT_REQUEST',
  SIGN_OUT_REQUEST_SUCCEEDED = 'SIGN_OUT_REQUEST_SUCCEEDED',
  RESET_MAINMODUL_ERRORS = 'RESET_MAINMODUL_ERRORS',
  DEFAULT_ACTION_TYPE = 'DEFAULT_ACTION_TYPE',
}

export interface DefaultActionType {
  type: MainModalActionTypes.DEFAULT_ACTION_TYPE;
}

export interface ResetMainmodulErrors {
  type: MainModalActionTypes.RESET_MAINMODUL_ERRORS;
}

export interface SignOutRequestSucceeded {
  type: MainModalActionTypes.SIGN_OUT_REQUEST_SUCCEEDED;
}

export interface SignOutRequest {
  type: MainModalActionTypes.SIGN_OUT_REQUEST;
}

export interface SigninConfirmPasswordFailed {
  type: MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD_FAILED;
  error: Error;
}

export interface SigninConfirmPasswordSucceeded {
  type: MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD_SUCCEEDED;
}

export interface SigninConfirmPassword {
  type: MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD;
  username: string;
  code: string;
  password: string;
}

export interface ForgotPasswordRequestFailed {
  type: MainModalActionTypes.FORGOT_PASSWORD_REQUEST_FAILED;
  error: Error;
}

export interface ForgotPasswordRequestSucceeded {
  type: MainModalActionTypes.FORGOT_PASSWORD_REQUEST_SUCCEEDED;
}
export interface ForgotPassword {
  type: MainModalActionTypes.FORGOT_PASSWORD;
  username: string;
}

export interface SignupConfirmCodeFailed {
  type: MainModalActionTypes.SIGNUP_CONFIRM_CODE_FAILED;
  error: Error;
}

export interface SignupConfirmCodeSucceeded {
  type: MainModalActionTypes.SIGNUP_CONFIRM_CODE_SUCCEEDED;
}

export interface ConfirmCode {
  type: MainModalActionTypes.CONFIRM_CODE;
  code: string;
  username: string;
}

export interface SignUp {
  type: MainModalActionTypes.SIGNUP;
  username: string;
  password: string;
}

export interface SignupFailed {
  type: MainModalActionTypes.SIGNUP_FAILED;
  error: Error;
}

export interface SignupSucceeded {
  type: MainModalActionTypes.SIGNUP_SUCCEEDED;
  user: any;
}

export interface SigninFailed {
  type: MainModalActionTypes.SIGNIN_FAILED;
  error: Error;
}

export interface SigninSucceeded {
  type: MainModalActionTypes.SIGNIN_SUCCEEDED;
}

export interface SetSignInEmailPassword {
  type: MainModalActionTypes.SET_SIGNIN_EMAIL_PASSWORD;
  username: string;
  password: string;
}

export interface ConfirmPassword {
  type: MainModalActionTypes.CONFIRM_PASSWORD;
  repeatPassword: string;
}

export interface SetSecondPassword {
  type: MainModalActionTypes.SET_SECOND_PASSWORD;
  password: string;
}

export interface SetPassword {
  type: MainModalActionTypes.SET_PASSWORD;
  password: string;
}

export interface SetSignupCode {
  type: MainModalActionTypes.SET_SIGNUP_CODE;
  code: string;
}

export interface ChangeModal {
  type: MainModalActionTypes.CHANGE_MODAL;
  open: boolean;
}

export interface SetStageAction {
  type: MainModalActionTypes.SET_STAGE;
  payload: StageSteps;
}

export interface SetRegisterEmail {
  type: MainModalActionTypes.SET_REGISTER_EMAIL;
  username: string;
}

export function defaultActionType(): DefaultActionType {
  return {
    type: MainModalActionTypes.DEFAULT_ACTION_TYPE,
  };
}

export function resetMainmodulErrors(): ResetMainmodulErrors {
  return {
    type: MainModalActionTypes.RESET_MAINMODUL_ERRORS,
  };
}

export function signOutRequestSucceeded(): SignOutRequestSucceeded {
  return {
    type: MainModalActionTypes.SIGN_OUT_REQUEST_SUCCEEDED,
  };
}

export function signOutRequest(): SignOutRequest {
  return {
    type: MainModalActionTypes.SIGN_OUT_REQUEST,
  };
}

export function signinConfirmPasswordFailed(error: Error): SigninConfirmPasswordFailed {
  return {
    type: MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD_FAILED,
    error,
  };
}

export function signinConfirmPasswordSucceeded(): SigninConfirmPasswordSucceeded {
  return {
    type: MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD_SUCCEEDED,
  };
}

export function signinConfirmPassword(username: string, code: string, password: string): SigninConfirmPassword {
  return {
    type: MainModalActionTypes.SIGNIN_CONFIRM_PASSWORD,
    username,
    code,
    password,
  };
}

export function forgotPasswordRequestFailed(error: Error): ForgotPasswordRequestFailed {
  return {
    type: MainModalActionTypes.FORGOT_PASSWORD_REQUEST_FAILED,
    error,
  };
}

export function forgotPasswordRequestSucceeded(): ForgotPasswordRequestSucceeded {
  return {
    type: MainModalActionTypes.FORGOT_PASSWORD_REQUEST_SUCCEEDED,
  };
}

export function forgotPassword(username: string): ForgotPassword {
  return {
    type: MainModalActionTypes.FORGOT_PASSWORD,
    username,
  };
}

export function signupConfirmCodeFailed(error: Error): SignupConfirmCodeFailed {
  return {
    type: MainModalActionTypes.SIGNUP_CONFIRM_CODE_FAILED,
    error,
  };
}

export function signupConfirmCodeSucceeded(): SignupConfirmCodeSucceeded {
  return {
    type: MainModalActionTypes.SIGNUP_CONFIRM_CODE_SUCCEEDED,
  };
}

export function signUp(username: string, password: string): SignUp {
  return {
    type: MainModalActionTypes.SIGNUP,
    username,
    password,
  };
}

export function signupFailed(error: Error): SignupFailed {
  return {
    type: MainModalActionTypes.SIGNUP_FAILED,
    error: error,
  };
}

export function signupSucceeded(user: any): SignupSucceeded {
  return {
    type: MainModalActionTypes.SIGNUP_SUCCEEDED,
    user,
  };
}

export function signinFailed(error: Error): SigninFailed {
  return {
    type: MainModalActionTypes.SIGNIN_FAILED,
    error: error,
  };
}

export function signinSucceeded(): SigninSucceeded {
  return {
    type: MainModalActionTypes.SIGNIN_SUCCEEDED,
  };
}

export function setSignInEmailPassword(username: string, password: string): SetSignInEmailPassword {
  return {
    type: MainModalActionTypes.SET_SIGNIN_EMAIL_PASSWORD,
    username,
    password,
  };
}

export function confirmCode(username: string, code: string): ConfirmCode {
  return {
    type: MainModalActionTypes.CONFIRM_CODE,
    username,
    code,
  };
}

export function confirmPassword(repeatPassword: string): ConfirmPassword {
  return {
    type: MainModalActionTypes.CONFIRM_PASSWORD,
    repeatPassword,
  };
}

export function setSecondPassword(password: string): SetSecondPassword {
  return {
    type: MainModalActionTypes.SET_SECOND_PASSWORD,
    password: password,
  };
}

export function setPassword(password: string): SetPassword {
  return {
    type: MainModalActionTypes.SET_PASSWORD,
    password: password,
  };
}

export function setSignupCode(code: string): SetSignupCode {
  return {
    type: MainModalActionTypes.SET_SIGNUP_CODE,
    code,
  };
}

export function closeModal(): ChangeModal {
  return {
    type: MainModalActionTypes.CHANGE_MODAL,
    open: false,
  };
}

export function openModal(): ChangeModal {
  return {
    type: MainModalActionTypes.CHANGE_MODAL,
    open: true,
  };
}

export function setStage(stage: StageSteps): SetStageAction {
  return {
    type: MainModalActionTypes.SET_STAGE,
    payload: stage,
  };
}

export function setRegisterEmail(username: string): SetRegisterEmail {
  return {
    type: MainModalActionTypes.SET_REGISTER_EMAIL,
    username,
  };
}

export type MainModalActions =
  | DefaultActionType
  | ResetMainmodulErrors
  | SignOutRequest
  | SignOutRequestSucceeded
  | SigninConfirmPasswordFailed
  | SigninConfirmPasswordSucceeded
  | SigninConfirmPassword
  | ForgotPasswordRequestFailed
  | ForgotPasswordRequestSucceeded
  | ForgotPassword
  | SignupConfirmCodeSucceeded
  | SignupConfirmCodeFailed
  | SignUp
  | SignupSucceeded
  | SignupFailed
  | SigninSucceeded
  | SigninFailed
  | SetSignInEmailPassword
  | ConfirmCode
  | ConfirmPassword
  | SetSecondPassword
  | SetPassword
  | SetSignupCode
  | ChangeModal
  | SetStageAction
  | SetRegisterEmail;
